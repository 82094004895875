/* global Cookies, FastClick */
(function() {
  'use strict';
  let techfeed = window.techfeed;
  if (!techfeed) {
    techfeed = window.techfeed = {};
  }
  if (!techfeed.common) {
    techfeed.common = {};
  }
  techfeed.common.getLoginUserId = function() {
    return $('#loginUserId').val();
  };
  techfeed.common.getApplicationId = function() {
    return $('#applicationId').val();
  };
  techfeed.common.getDeviceType = function() {
    return $('#deviceType').val();
  };
  // ==== Global message ==== //
  (function processGlobalMessage() {
    const MSG_KINDS = ['success', 'info', 'warning', 'danger'];

    const $globalMsg = $('#global-message');
    const $msg = $globalMsg.find('.message');
    function showMessage(msg, kind, dispTime) {
      if (!kind) {
        kind = 'info';
      }
      if (!dispTime) {
        dispTime = 5000;
      }
      MSG_KINDS.forEach(k => {
        $globalMsg.removeClass('alert-' + k);
      });
      $msg.text(msg);
      $globalMsg.addClass(`alert-${kind}`).show(0).delay(10).addClass('in');
      setTimeout(function() {
        $msg.text('');
        $globalMsg.removeClass('in').delay(400).hide(0);
        let cookieName = `msg-${kind}`;
        Cookies.remove(cookieName);
      }, dispTime);
    }
    techfeed.common.showMessage = showMessage;
    (function checkMsgFromServer() {
      MSG_KINDS.forEach(kind => {
        let cookieName = `msg-${kind}`;
        let msgFromServer = Cookies.get(cookieName);
        //console.log('checkMsgFromServer', cookieName, msgFromServer);
        if (msgFromServer) {
          // console.debug('Message from server found:', msgFromServer);
          showMessage(msgFromServer, kind);
        }
      });
      // 1秒おきにチェック
      setTimeout(checkMsgFromServer, 3000);
    })();
  })();

  // ==== Global Ajax error handler ==== //
  (function() {
    function onAjaxError(errEvent, xhr) {
      const responseJSON = xhr.responseJSON;
      let message;
      if (responseJSON) {
        message = responseJSON.message || 'エラーが起きました';
      } else {
        message = xhr.responseText;
      }
      techfeed.common.showMessage(message, 'warning', 5000);
    }
    // TODO: 自前でハンドルしている所もあるので一旦は設定しない
    //$(document).ajaxError(onAjaxError);
    techfeed.common.onAjaxError = onAjaxError;
  })();

  // ==== Global ajax settings ==== //
  const API_URL_PATTERN = /^\/api\//;
  $.ajaxPrefilter((options, originalOptions/*, jqXhr*/) => {
    // APIのURLだったら、acceptsにapplication/jsonを付ける
    if (API_URL_PATTERN.test(originalOptions.url)) {
      options.headers = options.headers || {};
      options.headers.Accept = 'application/json';
    }
  });

  // ==== Loading UI (blink our logo) ==== //
  (function processLoadingUI() {
    class LoadingUI {
      constructor() {
        this.$logoImg = $('#logo-image');
      }
      get LOGO_WIDTH() {
        return 25;
      }
      get UI_CHANGE_INTERVAL() {
        return 500;
      }
      begin(reverse) {
        reverse = !!reverse;
        let count = reverse ? 3 : 1;
        this.timer = setInterval(() => {
          if (reverse) {
            count--;
            if (count === 0) {
              count = 3;
            }
          } else {
            count++;
            if (count > 3) {
              count = 1;
            }
          }
          this.$logoImg.css('backgroundPosition', -(LoadingUI.LOGO_WIDTH * count) + 'px');
        }, LoadingUI.UI_CHANGE_INTERVAL);
      }
      end() {
        clearInterval(this.timer);
        this.$logoImg.css('backgroundPosition', 0);
      }
    }
    var loadingUI = new LoadingUI();
    $(document).ajaxStart(() => loadingUI.begin());
    $(document).ajaxComplete(() => loadingUI.end());
  })();

  // ==== Fast click ==== //
  (function processFastClick() {
    FastClick.attach(document.body);
  })();

  // ==== Popup image ==== //
  (function processMagnificPopup() {
    $('a.magnific-popup').magnificPopup({
      type: 'image',
      mainClass: 'mfp-with-zoom', // this class is for CSS animation below
      closeOnContentClick: true,
      zoom: {
        enabled: true, // By default it's false, so don't forget to enable it
        duration: 300, // duration of the effect, in milliseconds
        easing: 'ease-in-out', // CSS transition easing function

        // The "opener" function should return the element from which popup will be zoomed in
        // and to which popup will be scaled down
        // By defailt it looks for an image tag:
        opener: function(openerElement) {
          // openerElement is the element on which popup was initialized, in this case its <a> tag
          // you don't need to add "opener" option if this code matches your needs, it's defailt one.
          return openerElement.is('img') ? openerElement : openerElement.find('img');
        }
      }
    });
  })();

  (function processDefineIsLoggedIn() {
    function isLoggedIn() {
      return !!Cookies.get('authorization');
    }
    techfeed.common.isLoggedIn = isLoggedIn;
  })();

  // for old page
  (() => {
    const $html = $('html');
    // 開発中だけ有効に
    if (!$html.is('.development') && !$html.is('.staging') ) {
      return;
    }
    if (!$html.is('.old')) {
      return;
    }
    $('a[href^="/"]').each((i,el) => {
      const href = $(el).attr('href');
      $(el).attr('href', `${href}?old`);
    })
  })();
})();
